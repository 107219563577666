import styled from 'theme';
import Container from '_componentLibrary/Container';
import { primaryTheme } from 'theme';

const HeroText = styled.div`
  background-color: ${primaryTheme.colors.secondary};
  flex: 1;
  padding: 0;
  height: 460px;
  border-radius: 0 16px 16px 0;
  @media (max-width: 1500px) {
    height: 540px;
  }
  @media (max-width: 1350px) {
    height: auto;
    border-radius: 0;
  }
`;

const HeroContent = styled.div`
  padding: 3rem 8rem;
  text-align: center;
  @media (max-width: 1500px) {
    padding: 3rem 8rem;
    text-align: center;
  }
  @media (max-width: 1350px) {
    padding: 3rem 3rem;
  }
`;


const HeroImageContainer = styled(Container)`
  height: 460px;
  @media (max-width: 1500px) {
    height: 540px;
  }
  @media (max-width: 1350px) {
    display: none;
  }
`;

const HeroContainer = styled.div`
  display: flex;
  width: auto;
  padding: 8rem 0 3rem;
  justify-content: center;
  height: 460px;
  @media (max-width: 1500px) {
    padding: 4rem 5rem 3rem;
    height: 540px;
  }
  @media (max-width: 1350px) {
    padding: 0 0;
    flex-wrap: wrap;
    height: auto;
  }

`;

const ButtonContainer = styled(Container)`
  padding: 2rem 0 0;
`;

export { 
  ButtonContainer,
  HeroText,
  HeroContent,
  HeroContainer,
  HeroImageContainer
}
