import React from 'react';
import defaultImage from 'assets/images/default.png';
import {
  CardStyledImageWrapper,
  ThumbStyledImageWrapper,
  CardImage,
  ThumbImage,
  Placeholder,
  StyledImage,
  StyledImageWrapper
} from './styles.js';

const Image = ({
  // Use this src for urls
  src = defaultImage, 
  imageStyle, 
  maxWidth,
  maxHeight,
  height='100%',
  dataDriven = false,
  dataLoaded = false,
  alt="Default alternate attribute for an image.", 
  ...rest
}) => {
  if (dataDriven  && !dataLoaded) {
    return (
      <Placeholder />
    )
  } else if(imageStyle==='card') {
    return (
      <CardStyledImageWrapper height={height} maxWidth={maxWidth}>
        <CardImage imageStyle={imageStyle} 
          height={height}
          alt={alt}
          src={src} />
      </CardStyledImageWrapper>
    )
  } else if(imageStyle==='thumbnail') {
    return (
      <ThumbStyledImageWrapper height={height} maxWidth={maxWidth}>
        <ThumbImage imageStyle={imageStyle} 
          height={height}
          alt={alt}
          src={src} />
      </ThumbStyledImageWrapper>
    )
  } else {
    return (
      <StyledImageWrapper maxHeight={maxHeight} maxWidth={maxWidth}>
        <StyledImage imageStyle={imageStyle} 
          alt={alt}
          src={src} />
      </StyledImageWrapper>
    )
  }
}

export default Image;
