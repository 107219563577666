import * as React from 'react';
import { StyledText } from './styles.js';

const Text = ({
  display='block',
  textStyle,
  textAlign='inherit',
  textTransform='none',
  fontWeight,
  fontSize,
  fontStyle="normal",
  as,
  tag,
  hidden,
  children,
  color,
  className,
  ...rest
}) => {
  return (
    <StyledText
      color={color}
      textStyle={textStyle}
      textAlign={textAlign}
      display={display}
      textTransform={textTransform}
      fontWeight={fontWeight}
      fontStyle={fontStyle}
      fontSize={fontSize}
      as={tag}
      hidden={hidden}
      className={className}
      {...rest}
    >
      {children}
    </StyledText>
  );
}

export default Text;
