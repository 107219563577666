import Main from 'components/Main';
import { DisplayProvider } from 'contexts/displayProvider';

function App() {
  return (
    <DisplayProvider>
      <Main />
    </DisplayProvider>
  );
}

export default App;
