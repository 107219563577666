import styled, { primaryTheme, css } from 'theme';

const dividerStyles = {
  light: css`
    border: none;
    border-top: 1px solid ${primaryTheme.colors.secondary};
    padding: 2rem;
    width: 80%;
    @media (max-width: 1500px) {
      display: none;
    }
    @media (max-width: 1350px) {
      display: none;
    }
  `,
  showMobile: css`
    border: none;
    border-top: 2px solid ${primaryTheme.colors.secondary};
    padding: 0;
    width: 80%;
    display: none;
    @media (max-width: 1500px) {
      display: block;
      width: 60%;
    }
    @media (max-width: 1350px) {
      width: 100%;
    }
  `,
  default: css`
    border: none;
    border-top: 2px solid ${primaryTheme.colors.secondary};
    padding: 2rem;
    width: 80%;
    @media (max-width: 1500px) {
      width: 60%;
    }
    @media (max-width: 1350px) {
      display: none;
    }
  `,
};

export const StyledDivider = styled.hr`
  ${({ dividerStyle = 'default' }) => dividerStyles[dividerStyle]};
`;
