import React from 'react';
import {
  FrictionlessTextContainer,
} from './styles.js';
import { primaryTheme } from 'theme';
import grassBokeh from 'assets/images/grassbokeh.png';

import Text from '_componentLibrary/Text'
import Button from '_componentLibrary/Button'
import Image from '_componentLibrary/Image'

const FrictionlessText = () => {
  return (
    <FrictionlessTextContainer>
      <Text as='h1' fontSize='54px' fontWeight='bold'>Frictionless</Text>
      <Text as='h1' fontSize='36px' fontWeight='light' padding='2rem' textStyle='attention'>Payments for landscaping services should be fricitionless and client management should be an afterthought</Text>
    </FrictionlessTextContainer>
  );
}

export default FrictionlessText;

