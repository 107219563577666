import styled from 'theme';
import Container from '_componentLibrary/Container';
import { primaryTheme } from 'theme';

const LandingContainer = styled.div`
  padding: 1rem 0;
  @media (max-width: 1500px) {
    padding: 3rem 0;
  }
  @media (max-width: 1350px) {
    padding: 0;
  }

`;

const Hero = styled.div`
  background-color: ${primaryTheme.colors.secondary};
  flex: 1;
  padding: 0;
  height: 460px;
  border-radius: 0 16px 16px 0;
`;

const HeroContent = styled.div`
  padding: 3rem 8rem;
  text-align: center;
`;


const HeroImageContainer = styled(Container)`
  height: 460px;
`;

const HeroContainer = styled.div`
  display: flex;
  padding: 6rem 0 3rem;
  justify-content: center;
  height: 460px;
`;

const ButtonContainer = styled(Container)`
  padding 2rem 0 0;
`;

export { 
  ButtonContainer,
  LandingContainer, 
  Hero,
  HeroContent,
  HeroContainer,
  HeroImageContainer
}
