import styled, { primaryTheme } from 'theme';

const CardImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 16px 0 0 16px;
  @media (max-width: 1500px) {
    height: 540px;
  }
  @media (max-width: 1350px) {
    border-radius: 0;
  }
`;

const ThumbImage = styled.img`
  width: 100%;
  border-radius: 16px 16px 0 0;
  @media (max-width: 1350px) {
    border-radius: 0;
  }
`;

const CardStyledImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height};
  overflow: hidden;
  overflow: visible;
`;

const ThumbStyledImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height};
  overflow: hidden;
  overflow: visible;
`;

const StyledImage = styled.img`
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
`;

const StyledImageWrapper = styled.div`
  width: ${({maxWidth = '100%'}) => {return `${maxWidth}`}};
  height: ${({maxHeight = 'unset'}) => {return `${maxHeight}`}};
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Placeholder = styled.div`
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: ${primaryTheme.colors.backgroundDark};
`;

export {
  CardStyledImageWrapper,
  ThumbStyledImageWrapper,
  ThumbImage,
  CardImage,
  Placeholder,
  StyledImage,
  StyledImageWrapper
}
