import React from 'react';
import {
  OutlineContainer,
  OutlineWrapper
} from './styles.js';
import { primaryTheme } from 'theme';
import grassBokeh from 'assets/images/grassbokeh.png';

import Text from '_componentLibrary/Text'
import Button from '_componentLibrary/Button'
import Image from '_componentLibrary/Image'

const Outline = () => {
  return (
    <OutlineWrapper>
      <OutlineContainer>
        <Text as='h1' fontSize='64px' color={primaryTheme.colors.accentAlt} textStyle='callout'>Add time back to your day so you can focus on growing your business</Text>
      </OutlineContainer>
    </OutlineWrapper>
  );
}

export default Outline;

