import { useContext, useEffect } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Landing from 'components/_pages/Landing';
import NotFound from 'components/_pages/NotFound';
import { DisplayContext } from 'contexts/displayContext';

import {MainContainer} from  './styles.js';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  // Redirect,
  // useRouteMatch
} from 'react-router-dom';

function Main() {
  const displayContext = useContext(DisplayContext);

  displayContext.setShowModal(true);
  useEffect(() => { console.log(displayContext.showModal) },[displayContext.showModal]);

  return (
    <Router>
      <Header />
      <MainContainer>
        <Routes>
          {/* utility*/}
          {/* <Route path="/signup" component={AddUser} exact={true} /> */}
          <Route path='/' element={<Landing />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </MainContainer>
      <Footer />
    </Router>
  );
}

export default Main;
