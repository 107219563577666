import * as React from 'react';
import { StyledButton } from './styles';

const Button = ({
  textDecoration,
  buttonstyle,
  backgroundcolor, 
  backgroundHoverColor, 
  action,
  color, 
  enabled=true,
  children,
  ...rest
}) => {
  return(
    <StyledButton
      buttonstyle={buttonstyle}
      color={color}
      textDecoration={textDecoration}
      backgroundcolor={backgroundcolor}
      backgroundHoverColor={backgroundHoverColor}
      onClick={action}
      enabled={enabled}
      {...rest}
    >
      {children}
    </StyledButton>
)};

export default Button;
