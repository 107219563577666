import React from 'react';
import {
  ButtonContainer,
  HeroText,
  HeroContent,
  HeroContainer,
  HeroImageContainer,
} from './styles.js';
import { primaryTheme } from 'theme';
import grassBokeh from 'assets/images/grassbokeh.png';

import Text from '_componentLibrary/Text'
import Button from '_componentLibrary/Button'
import Image from '_componentLibrary/Image'
import 'hippo-component-library/dist/index.css'

const Hero = () => {
  return (
    <HeroContainer>
        <HeroImageContainer padding={0}>
          <Image imageStyle='card' height='460px' src={grassBokeh} />
        </HeroImageContainer>
        <HeroText>
          <HeroContent padding={0}>
            <Text as='h1' color={primaryTheme.colors.white} fontWeight='bold' textStyle='h1'>Why wait to get paid?</Text>
            <Text color={primaryTheme.colors.white} textStyle='heroDescription'>Payments and Registration Solutions made for Landscaping Businesses</Text>
            <ButtonContainer>
              <Button>Sign Up Today</Button>
            </ButtonContainer>
          </HeroContent>
        </HeroText>
    </HeroContainer>
  );
}

export default Hero;

