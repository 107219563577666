import styled from '../../theme';
import Link from '../../_componentLibrary/Link';
import primaryTheme from '../../theme/primaryTheme';

const Logo = styled(Link)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 1rem auto;
  width: 95px;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: auto; 
  width: auto;
  padding: 1rem 2vw;
  background-color: ${primaryTheme.colors.primary};
  z-index: 9999;

  @media (max-width: 1024px) {
    padding: 1rem 10vw;
  }
`;

export { 
  HeaderContainer, 
  Logo
}
