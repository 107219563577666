import * as React from 'react';
import primaryTheme from 'theme/primaryTheme';
import {StyledContainer} from './styles.js';

const Container = ({
  containerType,
  header='{null}',
  children,
  flex=1,
  display='flex',
  flexDirection='column',
  flexWrap='wrap',
  backgroundcolor=primaryTheme.colors.black,
  padding='0',
  height='100%',
  width='100%',
  overflow='hidden',
  ...rest
}) => {
  switch(containerType) {
    default:
      return (
        <StyledContainer
          header={header}
          flex={flex}
          flexDirection={flexDirection}
          backgroundcolor={backgroundcolor}
          padding={padding}
          height={height}
          width={width}
          flexWrap={flexWrap}
          display={display}
          overflow={overflow}
          {...rest}
        >
          {children}
        </StyledContainer>
      );
  }
}

export default Container;
