import React, { useState, useEffect } from 'react';
import SvgIcon from 'assets/svgs';
import { primaryTheme } from 'theme';
import {
  HeaderContainer,
  Logo,
} from './styles.js';

const Header = () => {
  const [width, setWidth] = useState(1280);

  useEffect(() => {
    setWidth(window.innerWidth)
  }, []);

  const mobile = width < 724;
  const viewBoxWidth = mobile ? 167.71 : 167.71;
  const viewBoxHeight = mobile ? 30 : 30;

  return (
    <HeaderContainer>
      <Logo linkstyle='headerLogo' target='_self' address='/'>
        <SvgIcon
          name={mobile ? 'mobileLogo' : 'mainLogo'}
          height={30}
          width={mobile ? 167.71 : 167.71}
          viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
          style={{cursor: 'pointer'}}
          fill={primaryTheme.colors.white}
          altFill={primaryTheme.colors.accent} />
      </Logo>
    </HeaderContainer>
  );
}

export default Header;
