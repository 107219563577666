import styled from 'theme';

export const StyledContainer = styled.div`
    flex             : ${({ flex }) => flex};
    display          : ${({ display }) => display};
    flex-direction   : ${({ flexDirection }) => flexDirection};
    flex-wrap        : ${({ flexWrap }) => flexWrap};
    background-color : ${({ backgroundcolor}) => backgroundcolor};
    padding          : ${({ padding }) => padding};
    height           : ${({ height }) => height};
    width            : ${({ width }) => width};
    overflow         : ${({ overflow }) => overflow};
    align-items: stretch;
`;
