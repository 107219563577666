import styled, {primaryTheme} from 'theme';
import Container from '_componentLibrary/Container';

const FrictionlessTextContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 0;
  text-align: center;
  border-radius: 16px;
  margin: 1rem 0 5rem;
`;

export { 
  FrictionlessTextContainer,
}
