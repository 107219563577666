import styled, {primaryTheme} from 'theme';
import Container from '_componentLibrary/Container';
import grassOutline from 'assets/images/grassoutline.png';

const OutlineWrapper = styled.div`
  padding: 0;

  @media (max-width: 1500px) {
    padding: 3rem 8rem 0;
  }
`

const OutlineContainer = styled(Container)`
  background: url(${grassOutline}) no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 0;
  height: 469px;
  text-align: center;
  border-radius: 16px;
  margin: 5rem 0 9rem;
  @media (max-width: 1500px) {
    width: auto;
    height: 369px;
  }

  @media (max-width: 1350px) {
    background: none;
    height: auto;
    margin: 0;
  }
`;

export { 
  OutlineContainer,
  OutlineWrapper
}
