import React from 'react';
import {
  PartnershipTextContainer,
} from './styles.js';
import { primaryTheme } from 'theme';
import grassBokeh from 'assets/images/grassbokeh.png';

import Text from '_componentLibrary/Text'
import Button from '_componentLibrary/Button'
import Image from '_componentLibrary/Image'

const PartnershipText = () => {
  return (
    <PartnershipTextContainer>
      <Text as='h1' fontSize='54px' fontWeight='bold'>Partnership</Text>
      <Text as='h1' fontWeight='light' padding='2rem' textStyle='attention'>Every business we work with is a partner, we want to hear from you! What makes your day harder, what challenges you face, how we can improve your experience</Text>
    </PartnershipTextContainer>
  );
}

export default PartnershipText;

