import React from 'react';
import Hero from 'components/Hero';
import SmallBusiness from 'components/SmallBusiness';
import CardsSection from 'components/CardsSection';
import Outline from 'components/Outline';
import PartnershipText from 'components/PartnershipText';
import FrictionlessText from 'components/FrictionlessText';
import {
  LandingContainer,
} from './styles.js';

import Text from '_componentLibrary/Text'
import Divider from '_componentLibrary/Divider'

const Landing = () => {
  return (
    <LandingContainer>
      <Hero />
      <CardsSection />
      <Divider dividerStyle='showMobile' />
      <Outline />
      <Divider dividerStyle='showMobile' />
      <PartnershipText />
      <Divider />
      <SmallBusiness />
      <Divider />
      <FrictionlessText />
    </LandingContainer>
  );
}

export default Landing;

