import styled from 'theme';
import Container from '_componentLibrary/Container';
import { primaryTheme } from 'theme';

const SmallBusinessHeroText = styled.div`
  background-color: ${primaryTheme.colors.primary};
  flex: 2;
  padding: 0;
  height: 460px;
  border-radius: 0 16px 16px 0;
  @media (max-width: 1350px) {
    height: auto;
    border-radius: 0;
  }

`;

const SmallBusinessHeroContent = styled.div`
  padding: 3rem 8rem 0;
  text-align: center;
  @media (max-width: 1350px) {
    padding: 3rem 8rem 5rem;
  }

`;


const SmallBusinessHeroImageContainer = styled(Container)`
  height: 460px;
  @media (max-width: 1500px) {
    border-radius: 16px 0 0 16px;
  }
  @media (max-width: 1350px) {
    display: none;
  }

`;

const SmallBusinessHeroContainer = styled.div`
  display: flex;
  padding: 4rem 0 9rem;
  margin-bottom: 0;
  justify-content: center;
  height: 460px;
  @media (max-width: 1500px) {
    padding: 5rem 8rem 10rem;
    width: auto;
  }
  @media (max-width: 1350px) {
    height: auto;
    padding: 0;
  }

`;

export { 
  SmallBusinessHeroText,
  SmallBusinessHeroContent,
  SmallBusinessHeroContainer,
  SmallBusinessHeroImageContainer
}
