import styled from '../../theme';
import theme from '../../theme/primaryTheme';

const StyledFooter = styled.div`
  background-color: ${theme.colors.secondary};
  color: ${theme.colors.tertiary};
  padding: 1rem 10vw;
  z-index: 1;
  position: relative;
`;

export {
  StyledFooter
};
