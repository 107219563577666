import React from 'react';
import { StyledFooter } from './styles';

import { Text } from 'hippo-component-library'
import 'hippo-component-library/dist/index.css'

const Footer = () => {
  return (
    <StyledFooter className='footer'>
      <Text tag='p' sizeFactor={1} color='rgba( 255,255,255,1 )'>Proudly based in Cary, North Carolina</Text>
    </ StyledFooter>
  );
}
// const Footer = () => {
//   return (
//     <StyledFooter className='footer'>
//       <Text tag='p' sizeFactor={0.6} color='rgba( 45,45,45,1 )'>Give us a follow and help us grow the community</Text>
//       <Links>
//         <Link name='linkedIn'
//           linkstyle='inlineFooter'
//           target='_blank'
//           address='https://www.linkedin.com/company/adaptiv-solutions/about'>
//           <SvgIcon name='linkedIn'viewBox='0 0 448 512' fill='#fff' />
//         </ Link>
//         <Link name='instagram' 
//           linkstyle='inlineFooter'
//           target='_blank'
//           address='https://www.instagram.com/thisisadaptiv/'>
//           <SvgIcon name='instagram' viewBox='0 0 448 512' fill='#fff' />
//         </ Link>
//       </Links>
//     </ StyledFooter>
//   );
// }

export default Footer;
