import { useState } from 'react';
import { DisplayContext } from './displayContext';

export const DisplayProvider = ({ children }) => {
  const [ showModal, setShowModal ] = useState(false); 

  // const setModal = (displayContext) => {

  //   return {
  //     modal: displayContext.modal,
  //   };
  // };

  return (
    <DisplayContext.Provider value={{ showModal: showModal, setShowModal: setShowModal }}>
      {children}
    </DisplayContext.Provider>
  );
};


