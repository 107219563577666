import React from 'react';
import {
  SmallBusinessHeroText,
  SmallBusinessHeroContent,
  SmallBusinessHeroContainer,
  SmallBusinessHeroImageContainer,
} from './styles.js';
import { primaryTheme } from 'theme';
import cary from 'assets/images/cary.png';

import Text from '_componentLibrary/Text';
import Image from '_componentLibrary/Image';

const SmallBusiness = () => {
  return (
    <SmallBusinessHeroContainer>
      <SmallBusinessHeroImageContainer padding={0}>
        <Image imageStyle='card' height='460px' src={cary} />
      </SmallBusinessHeroImageContainer>
      <SmallBusinessHeroText>
        <SmallBusinessHeroContent padding={0}>
          <Text fontSize='48px' fontWeight='bold' color='white'>Small businesses are the backbone of the local community</Text>
          <Text fontSize='22px' color='white'>Managing payments, scheduling work and providing excellent care to the person down the street should not be hindered by admin work</Text>
        </SmallBusinessHeroContent>
      </SmallBusinessHeroText>
    </SmallBusinessHeroContainer>
  );
}

export default SmallBusiness;

