import React from 'react';
import { StyledLink, StyledNavLink } from './styles';
import { primaryTheme } from 'theme';

const Link = ({
  address, 
  func, 
  download=false,
  name, 
  linkstyle,
  backgroundcolor="unset",
  navLink=true,
  color=primaryTheme.colors.primary,
  text, 
  textDecoration="none", 
  target, 
  children,
  ...props
}) => {
  const LinkText = text || children || address;
  const linkAddress = download ? '' : address || func;
  const downloadLink = download ? address : false; 

  if (navLink === true) {
    return(
      <StyledNavLink to={linkAddress}
         color={color}
         download={downloadLink}
         linkstyle={linkstyle}
         backgroundcolor={backgroundcolor}
         name={name}
         target={target}
         textDecoration={textDecoration}
         theme={primaryTheme}
         {...props}
      >
        {LinkText}
      </StyledNavLink>
    )
  } else {
    return (
      <StyledLink href={linkAddress}
         color={color}
         download={downloadLink}
         linkstyle={linkstyle}
         backgroundcolor={backgroundcolor}
         name={name}
         target={target}
         textDecoration={textDecoration}
         theme={primaryTheme}
         {...props}
      >
        {LinkText}
      </StyledLink>
    )
  }
}

export default Link;
