const primaryTheme = {
  colors: {
    primary:               '#13112E',
    secondary:             '#BD2B58',
    secondaryHover:        '#9F3859',
    secondaryHoverLight:   'rgba(255,255,255,0.9)',
    tertiary:              '#EDC7B7',
    reverse:               '#FBF8F7',
    white:                 '#FFFFFF',
    richBlack:             '#000D0D',
    accent:                '#E97852',
    accentAlt:             '#668825',
    accentLight:           '#E97852',
    accentHover:           '#E97852',
    confirmation:          '#81CB3F',
    error:                 '#E32E07',
    disabled:              '#848687',
    background:            '#FDFDFD',
    backgroundDark:        '#1f2833',
    divider:               '#DCDCDC',
    backgroundDarker:      '#0B0C10',
    backgroundLighter:     '#F4F6F7',
    backgroundLight:       '#FBF8F7',
    backgroundTransparent: 'rgba( 23, 19, 25, 0.85 )',
    transparent:           'transparent',
    border:                'rgba(25,17,11,0.25)',
    inputHover:            '#0075E3' 
  },
  breakpoints: {
    small: '480px',
    medium: '768px',
    large: '1024px',
  },
  sizes: {
    mobileGutter: '2rem',
    desktopGutter: '5rem',
    maxSiteWidth: '1440px;',
    borderRadius: '0.25rem;',
  },
  fonts: {
    primary: 'Arial',
  },
};

export default primaryTheme;
