import React from 'react';
import { StyledDivider } from './styles.js';

const Divider = ({
    dividerStyle,
  }) => {
  return (
    <StyledDivider dividerStyle={dividerStyle} />
  );
}

export default Divider;

