import styled, {primaryTheme } from 'theme';
import { css } from 'theme';

const buttonStyles = {
  unstyled: css`
    padding: 0;
    margin: 0;
    background: transparent !important;
    font-size: 0;

    &:hover {
      background: transparent;
    }
  `,
  submit: css`
    width: 100%;
    appearance: none;
    border: none;
    border-radius: 0.25rem;
    box-shadow: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1rem;
    @media (max-width: 700px) {
      font-size: 0.7rem;
    }

    font-weight: 700;
    padding: 0.5rem 1.4rem;
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    white-space: nowrap;

    &:disabled {
      background-color: ${primaryTheme.colors.disabled};
      opacity: 0.5;
      cursor: not-allowed;
    }
  `,

  default: css`
    border: none;
    border-radius: 0.25rem;
    box-shadow: none;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    padding: 1.4rem 2rem;
    white-space: nowrap;
    &:hover{
      outline: 0;
      background-color: ${primaryTheme.colors.secondaryHoverLight};
      border: 2px solid ${primaryTheme.colors.primary};
    }
    &:focus{
      outline: 0;
      background-color: ${primaryTheme.colors.tertiary};
      border: 2px solid ${primaryTheme.colors.primary};
    }
    @media (max-width: 700px) {
      font-size: 0.7rem;
    }
  `,
};

export const StyledButton = styled.button`
  ${({ buttonstyle = 'default' }) => buttonStyles[buttonstyle]};
  text-decoration: ${({ textDecoration }) => textDecoration};
  background-color: ${({ backgroundcolor, enabled }) => enabled ? backgroundcolor : primaryTheme.colors.disabled};
  color: ${({ color }) => color};
  cursor: ${({ enabled }) => enabled ? 'pointer' : 'not-allowed'};
  opacity: ${({ enabled }) => enabled ? '100%' : '50%'};

  &:hover{
    background: ${({ backgroundHoverColor }) => backgroundHoverColor};
  }
`;
