import React from 'react';
import {
  CardImageContainer,
  CardTextContainer,
  CardContainer,
  CardsContainer,
} from './styles.js';
import { primaryTheme } from 'theme';
import weedEater from 'assets/images/weedeater.png';
import landscapedYard from 'assets/images/landscapedyard.png';
import lawnMower from 'assets/images/lawnmower.png';

import Text from '_componentLibrary/Text'
import Image from '_componentLibrary/Image'
import 'hippo-component-library/dist/index.css'

const CardsSection = () => {
  return (
    <CardsContainer>
      <CardContainer>
        <CardImageContainer>
          <Image imageStyle='thumbnail' src={weedEater} />
        </CardImageContainer>
        <CardTextContainer>
          <Text as='h3' fontWeight='bold' fontSize='48px' textStyle='h3'>Schedule Jobs</Text>
          <Text as='p' fontSize='28px' textStyle='cardDescription'>Manage and gain visibility into your client scheduling and get notified of any pressing changes to address</Text>
        </CardTextContainer>
      </CardContainer>
      <CardContainer>
        <CardImageContainer>
          <Image imageStyle='thumbnail' src={landscapedYard} />
        </CardImageContainer>
        <CardTextContainer>
          <Text as='h3' fontWeight='bold' fontSize='48px' textStyle='h3'>Make Payments</Text>
          <Text as='p' fontSize='28px' textStyle='cardDescription'>Client payment details are captured at signup. Payments can be processed as soon as the completion of a job</Text>
        </CardTextContainer>
      </CardContainer>
      <CardContainer>
        <CardImageContainer>
          <Image imageStyle='thumbnail' src={lawnMower} />
        </CardImageContainer>
        <CardTextContainer>
          <Text as='h3' fontWeight='bold' fontSize='48px' textStyle='h3'>Remove Friction</Text>
          <Text as='p' fontSize='28px' textStyle='cardDescription'>Allow new clients to sign up with ease, capturing their preferences and add them to your schedule</Text>
        </CardTextContainer>
      </CardContainer>
    </CardsContainer>
  );
}

export default CardsSection;
