import * as React from 'react';

const getPath = ( props ) => {
  const { 
    altFill,
    fill, 
    name 
  } = props; 

  // alphabetized
  switch (name) {
    case 'mobileLogo':
      return (
        <>
          <path fill={fill} d="M15.77,2.46c0,.87-.66,1.58-1.7,1.58s-1.66-.71-1.64-1.58c-.02-.91.64-1.6,1.66-1.6s1.66.68,1.68,1.6ZM12.52,15.42V5.28h3.15v10.14h-3.15Z"/>
          <path fill={fill} d="M18.2.69h3.15v5.79h.04c.6-.87,1.66-1.43,3.07-1.43,2.43,0,4.21,2.01,4.19,5.12,0,3.65-2.32,5.47-4.65,5.47-1.18,0-2.32-.44-3.05-1.64h-.04l-.12,1.41h-2.68c.04-.66.08-1.89.08-3.03V.69ZM21.35,11.1c0,.21.02.39.06.56.21.85.93,1.49,1.85,1.49,1.35,0,2.18-1.04,2.18-2.86,0-1.58-.71-2.82-2.18-2.82-.85,0-1.64.64-1.85,1.56-.04.19-.06.37-.06.58v1.49Z"/>
          <path fill={fill} d="M33.85,2.46c0,.87-.66,1.58-1.7,1.58s-1.66-.71-1.64-1.58c-.02-.91.64-1.6,1.66-1.6s1.66.68,1.68,1.6ZM30.6,15.42V5.28h3.15v10.14h-3.15Z"/>
          <path fill={fill} d="M36.26,12.66c.58.35,1.78.77,2.72.77s1.35-.33,1.35-.85-.31-.77-1.49-1.16c-2.09-.71-2.9-1.85-2.88-3.03,0-1.91,1.62-3.34,4.13-3.34,1.18,0,2.24.27,2.86.58l-.56,2.18c-.46-.25-1.33-.58-2.2-.58-.77,0-1.2.31-1.2.83,0,.48.39.73,1.64,1.16,1.93.66,2.74,1.64,2.76,3.13,0,1.89-1.49,3.3-4.4,3.3-1.33,0-2.51-.31-3.28-.73l.56-2.26Z"/>
          <path fill={fill} d="M52.89,15.17c-.56.25-1.62.48-2.82.48-3.28,0-5.37-2.01-5.37-5.21,0-2.97,2.03-5.39,5.81-5.39.83,0,1.74.15,2.41.39l-.5,2.34c-.37-.17-.93-.31-1.76-.31-1.66,0-2.74,1.18-2.72,2.84,0,1.87,1.24,2.84,2.78,2.84.75,0,1.33-.12,1.8-.33l.37,2.34Z"/>
          <path fill={fill} d="M64.19,12.16c0,1.33.04,2.41.08,3.26h-2.74l-.15-1.43h-.06c-.39.62-1.35,1.66-3.17,1.66-2.05,0-3.57-1.29-3.57-4.42v-5.95h3.17v5.45c0,1.47.48,2.36,1.58,2.36.87,0,1.37-.6,1.58-1.1.08-.19.1-.44.1-.68v-6.03h3.17v6.88Z"/>
          <path fill={fill} d="M66.7,12.66c.58.35,1.78.77,2.72.77s1.35-.33,1.35-.85-.31-.77-1.49-1.16c-2.09-.71-2.9-1.85-2.88-3.03,0-1.91,1.62-3.34,4.13-3.34,1.18,0,2.24.27,2.86.58l-.56,2.18c-.46-.25-1.33-.58-2.2-.58-.77,0-1.2.31-1.2.83,0,.48.39.73,1.64,1.16,1.93.66,2.74,1.64,2.76,3.13,0,1.89-1.49,3.3-4.4,3.3-1.33,0-2.51-.31-3.28-.73l.56-2.26Z"/>
          <path fill={fill} d="M80.02,1.63c.97-.17,2.34-.29,4.27-.29s3.34.37,4.27,1.12c.89.71,1.49,1.87,1.49,3.24s-.46,2.53-1.29,3.32c-1.08,1.02-2.68,1.47-4.54,1.47-.41,0-.79-.02-1.08-.06v5h-3.13V1.63ZM83.15,7.97c.27.06.6.08,1.06.08,1.68,0,2.72-.85,2.72-2.28,0-1.29-.89-2.05-2.47-2.05-.64,0-1.08.06-1.31.12v4.13Z"/>
          <path fill={fill} d="M100.05,12.95c0,.97.04,1.91.15,2.47h-2.84l-.19-1.02h-.06c-.66.81-1.7,1.24-2.9,1.24-2.05,0-3.28-1.49-3.28-3.11,0-2.63,2.36-3.9,5.95-3.88v-.15c0-.54-.29-1.31-1.85-1.31-1.04,0-2.14.35-2.8.77l-.58-2.03c.71-.39,2.09-.89,3.94-.89,3.38,0,4.46,1.99,4.46,4.38v3.53ZM96.98,10.63c-1.66-.02-2.94.37-2.94,1.6,0,.81.54,1.2,1.24,1.2.79,0,1.43-.52,1.64-1.16.04-.17.06-.35.06-.54v-1.1Z"/>
          <path fill={fill} d="M104.65,5.28l1.51,4.98c.17.6.37,1.35.5,1.89h.06c.15-.54.31-1.31.46-1.89l1.24-4.98h3.38l-2.36,6.68c-1.45,4.02-2.43,5.64-3.57,6.66-1.1.95-2.26,1.29-3.05,1.39l-.66-2.68c.39-.06.89-.25,1.37-.54.48-.25,1-.75,1.31-1.26.1-.15.17-.31.17-.46,0-.1-.02-.27-.15-.52l-3.71-9.27h3.5Z"/>
          <path fill={fill} d="M10.31,0v15.42s-3.54,0-3.54,0v-6.5c0-.23-.23-.38-.44-.29l-2.84,1.22c-.12.05-.19.16-.19.29v5.28s-3.3,0-3.3,0V0s3.3,0,3.3,0v5.25s.03,1.17.03,1.17c0,.22.23.37.43.29l3.04-1.2c.12-.05.2-.16.2-.29V0s3.3,0,3.3,0Z"/>
        </>
      );
    case 'mainLogo':
      return (
        <>
          <path fill={fill} d="M23.66,3.68c0,1.31-1,2.36-2.55,2.36s-2.49-1.06-2.46-2.36c-.03-1.37.96-2.4,2.49-2.4s2.49,1.03,2.52,2.4ZM18.77,23.13V7.91h4.73v15.21h-4.73Z"/>
          <path fill={fill} d="M27.3,1.04h4.73v8.68h.06c.9-1.31,2.49-2.15,4.6-2.15,3.64,0,6.31,3.02,6.28,7.68,0,5.47-3.48,8.21-6.97,8.21-1.77,0-3.48-.65-4.57-2.46h-.06l-.19,2.12h-4.01c.06-1,.12-2.83.12-4.54V1.04ZM32.02,16.66c0,.31.03.59.09.84.31,1.28,1.4,2.24,2.77,2.24,2.02,0,3.27-1.56,3.27-4.29,0-2.36-1.06-4.23-3.27-4.23-1.28,0-2.46.96-2.77,2.33-.06.28-.09.56-.09.87v2.24Z"/>
          <path fill={fill} d="M50.78,3.68c0,1.31-1,2.36-2.55,2.36s-2.49-1.06-2.46-2.36c-.03-1.37.96-2.4,2.49-2.4s2.49,1.03,2.52,2.4ZM45.9,23.13V7.91h4.73v15.21h-4.73Z"/>
          <path fill={fill} d="M54.39,18.99c.87.53,2.68,1.15,4.07,1.15s2.02-.5,2.02-1.28-.47-1.15-2.24-1.74c-3.14-1.06-4.35-2.77-4.32-4.54,0-2.86,2.43-5.01,6.19-5.01,1.77,0,3.36.4,4.29.87l-.84,3.27c-.68-.37-1.99-.87-3.3-.87-1.15,0-1.8.47-1.8,1.24,0,.72.59,1.09,2.46,1.74,2.89,1,4.11,2.46,4.14,4.7,0,2.83-2.24,4.95-6.59,4.95-1.99,0-3.76-.47-4.91-1.09l.84-3.39Z"/>
          <path fill={fill} d="M79.34,22.75c-.84.37-2.43.72-4.23.72-4.91,0-8.06-3.02-8.06-7.81,0-4.45,3.05-8.09,8.71-8.09,1.24,0,2.61.22,3.61.59l-.75,3.52c-.56-.25-1.4-.47-2.64-.47-2.49,0-4.11,1.77-4.07,4.26,0,2.8,1.87,4.26,4.17,4.26,1.12,0,1.99-.19,2.71-.5l.56,3.51Z"/>
          <path fill={fill} d="M96.29,18.24c0,1.99.06,3.61.12,4.88h-4.11l-.22-2.15h-.09c-.59.93-2.02,2.49-4.76,2.49-3.08,0-5.35-1.93-5.35-6.63V7.91h4.76v8.18c0,2.21.72,3.55,2.36,3.55,1.31,0,2.05-.9,2.36-1.65.12-.28.16-.65.16-1.03V7.91h4.76v10.33Z"/>
          <path fill={fill} d="M100.06,18.99c.87.53,2.68,1.15,4.07,1.15s2.02-.5,2.02-1.28-.47-1.15-2.24-1.74c-3.14-1.06-4.35-2.77-4.32-4.54,0-2.86,2.43-5.01,6.19-5.01,1.77,0,3.36.4,4.29.87l-.84,3.27c-.68-.37-1.99-.87-3.3-.87-1.15,0-1.8.47-1.8,1.24,0,.72.59,1.09,2.46,1.74,2.89,1,4.11,2.46,4.14,4.7,0,2.83-2.24,4.95-6.59,4.95-1.99,0-3.76-.47-4.91-1.09l.84-3.39Z"/>
          <path fill={fill} d="M120.03,2.44c1.46-.25,3.52-.44,6.41-.44s5.01.56,6.41,1.68c1.34,1.06,2.24,2.8,2.24,4.85s-.68,3.79-1.93,4.98c-1.62,1.52-4.01,2.21-6.81,2.21-.62,0-1.18-.03-1.62-.09v7.5h-4.7V2.44ZM124.72,11.96c.4.09.9.12,1.59.12,2.52,0,4.07-1.28,4.07-3.42,0-1.93-1.34-3.08-3.7-3.08-.96,0-1.62.09-1.96.19v6.19Z"/>
          <path fill={fill} d="M150.08,19.42c0,1.46.06,2.86.22,3.7h-4.26l-.28-1.52h-.09c-1,1.21-2.55,1.87-4.35,1.87-3.08,0-4.91-2.24-4.91-4.67,0-3.95,3.55-5.85,8.93-5.82v-.22c0-.81-.44-1.96-2.77-1.96-1.56,0-3.2.53-4.2,1.15l-.87-3.05c1.06-.59,3.14-1.34,5.91-1.34,5.07,0,6.69,2.99,6.69,6.56v5.29ZM145.47,15.94c-2.49-.03-4.42.56-4.42,2.4,0,1.21.81,1.8,1.87,1.8,1.18,0,2.15-.78,2.46-1.74.06-.25.09-.53.09-.81v-1.65Z"/>
          <path fill={fill} d="M156.98,7.91l2.27,7.47c.25.9.56,2.02.75,2.83h.09c.22-.81.47-1.96.68-2.83l1.87-7.47h5.07l-3.55,10.02c-2.18,6.03-3.64,8.46-5.35,9.99-1.65,1.43-3.39,1.93-4.57,2.08l-1-4.01c.59-.09,1.34-.37,2.05-.81.72-.37,1.49-1.12,1.96-1.9.16-.22.25-.47.25-.68,0-.16-.03-.4-.22-.78l-5.57-13.9h5.26Z"/>
          <path fill={fill} d="M15.47,0v23.13s-5.3,0-5.3,0v-9.75c0-.34-.35-.57-.66-.44l-4.26,1.83c-.17.07-.29.25-.29.44v7.92s-4.95,0-4.95,0V0s4.95,0,4.95,0v7.87s.05,1.75.05,1.75c0,.33.34.55.65.43l4.57-1.81c.18-.07.3-.25.3-.44V0s4.95,0,4.95,0Z"/>
        </>
      );
    case 'altLogo':
      return (
        <>
          <path fill={fill} d="M15.77,2.46c0,.87-.66,1.58-1.7,1.58s-1.66-.71-1.64-1.58c-.02-.91.64-1.6,1.66-1.6s1.66.68,1.68,1.6ZM12.52,15.42V5.28h3.15v10.14h-3.15Z"/>
          <path fill={fill} d="M18.2.69h3.15v5.79h.04c.6-.87,1.66-1.43,3.07-1.43,2.43,0,4.21,2.01,4.19,5.12,0,3.65-2.32,5.47-4.65,5.47-1.18,0-2.32-.44-3.05-1.64h-.04l-.12,1.41h-2.68c.04-.66.08-1.89.08-3.03V.69ZM21.35,11.1c0,.21.02.39.06.56.21.85.93,1.49,1.85,1.49,1.35,0,2.18-1.04,2.18-2.86,0-1.58-.71-2.82-2.18-2.82-.85,0-1.64.64-1.85,1.56-.04.19-.06.37-.06.58v1.49Z"/>
          <path fill={fill} d="M33.85,2.46c0,.87-.66,1.58-1.7,1.58s-1.66-.71-1.64-1.58c-.02-.91.64-1.6,1.66-1.6s1.66.68,1.68,1.6ZM30.6,15.42V5.28h3.15v10.14h-3.15Z"/>
          <path fill={fill} d="M36.26,12.66c.58.35,1.78.77,2.72.77s1.35-.33,1.35-.85-.31-.77-1.49-1.16c-2.09-.71-2.9-1.85-2.88-3.03,0-1.91,1.62-3.34,4.13-3.34,1.18,0,2.24.27,2.86.58l-.56,2.18c-.46-.25-1.33-.58-2.2-.58-.77,0-1.2.31-1.2.83,0,.48.39.73,1.64,1.16,1.93.66,2.74,1.64,2.76,3.13,0,1.89-1.49,3.3-4.4,3.3-1.33,0-2.51-.31-3.28-.73l.56-2.26Z"/>
          <path fill={fill} d="M52.89,15.17c-.56.25-1.62.48-2.82.48-3.28,0-5.37-2.01-5.37-5.21,0-2.97,2.03-5.39,5.81-5.39.83,0,1.74.15,2.41.39l-.5,2.34c-.37-.17-.93-.31-1.76-.31-1.66,0-2.74,1.18-2.72,2.84,0,1.87,1.24,2.84,2.78,2.84.75,0,1.33-.12,1.8-.33l.37,2.34Z"/>
          <path fill={fill} d="M64.19,12.16c0,1.33.04,2.41.08,3.26h-2.74l-.15-1.43h-.06c-.39.62-1.35,1.66-3.17,1.66-2.05,0-3.57-1.29-3.57-4.42v-5.95h3.17v5.45c0,1.47.48,2.36,1.58,2.36.87,0,1.37-.6,1.58-1.1.08-.19.1-.44.1-.68v-6.03h3.17v6.88Z"/>
          <path fill={fill} d="M66.7,12.66c.58.35,1.78.77,2.72.77s1.35-.33,1.35-.85-.31-.77-1.49-1.16c-2.09-.71-2.9-1.85-2.88-3.03,0-1.91,1.62-3.34,4.13-3.34,1.18,0,2.24.27,2.86.58l-.56,2.18c-.46-.25-1.33-.58-2.2-.58-.77,0-1.2.31-1.2.83,0,.48.39.73,1.64,1.16,1.93.66,2.74,1.64,2.76,3.13,0,1.89-1.49,3.3-4.4,3.3-1.33,0-2.51-.31-3.28-.73l.56-2.26Z"/>
          <path fill={fill} d="M80.02,1.63c.97-.17,2.34-.29,4.27-.29s3.34.37,4.27,1.12c.89.71,1.49,1.87,1.49,3.24s-.46,2.53-1.29,3.32c-1.08,1.02-2.68,1.47-4.54,1.47-.41,0-.79-.02-1.08-.06v5h-3.13V1.63ZM83.15,7.97c.27.06.6.08,1.06.08,1.68,0,2.72-.85,2.72-2.28,0-1.29-.89-2.05-2.47-2.05-.64,0-1.08.06-1.31.12v4.13Z"/>
          <path fill={fill} d="M100.05,12.95c0,.97.04,1.91.15,2.47h-2.84l-.19-1.02h-.06c-.66.81-1.7,1.24-2.9,1.24-2.05,0-3.28-1.49-3.28-3.11,0-2.63,2.36-3.9,5.95-3.88v-.15c0-.54-.29-1.31-1.85-1.31-1.04,0-2.14.35-2.8.77l-.58-2.03c.71-.39,2.09-.89,3.94-.89,3.38,0,4.46,1.99,4.46,4.38v3.53ZM96.98,10.63c-1.66-.02-2.94.37-2.94,1.6,0,.81.54,1.2,1.24,1.2.79,0,1.43-.52,1.64-1.16.04-.17.06-.35.06-.54v-1.1Z"/>
          <path fill={fill} d="M104.65,5.28l1.51,4.98c.17.6.37,1.35.5,1.89h.06c.15-.54.31-1.31.46-1.89l1.24-4.98h3.38l-2.36,6.68c-1.45,4.02-2.43,5.64-3.57,6.66-1.1.95-2.26,1.29-3.05,1.39l-.66-2.68c.39-.06.89-.25,1.37-.54.48-.25,1-.75,1.31-1.26.1-.15.17-.31.17-.46,0-.1-.02-.27-.15-.52l-3.71-9.27h3.5Z"/>
          <path fill={fill} d="M10.31,0v15.42s-3.54,0-3.54,0v-6.5c0-.23-.23-.38-.44-.29l-2.84,1.22c-.12.05-.19.16-.19.29v5.28s-3.3,0-3.3,0V0s3.3,0,3.3,0v5.25s.03,1.17.03,1.17c0,.22.23.37.43.29l3.04-1.2c.12-.05.2-.16.2-.29V0s3.3,0,3.3,0Z"/>
        </>
      );
    default:
      return <path />;
  }
};

export default getPath;
