import React, { Component } from 'react';
import styled from '../../../theme';
import badPathImage from '../../../assets/images/404.png';

const StyledNotFound = styled.div`
`;

class NotFound extends Component {
  render() {
    return (
      <StyledNotFound>
        <img alt='URL not found' src={badPathImage} />
      </ StyledNotFound>
    );
  }
}

export default NotFound;
