import styled from 'theme';
import Container from '_componentLibrary/Container';
import { primaryTheme } from 'theme';

const CardsContainer = styled(Container)`
  flex-wrap: nowrap;
  flex-direction: row;
  padding: 4rem 0 4rem;
  @media (max-width: 1500px) {
    padding: 4rem 3.25rem 4rem;
    width: auto;
  } 
  @media (max-width: 1350px) {
    flex-direction: column;
    padding: 0 0 4rem;
  } 
`;

const CardImageContainer = styled(Container)`
  height: 150px;
  padding: 0;
  @media (max-width: 1350px) {
    height: 300px;
  }
`;

const CardTextContainer = styled(Container)`
  @media (max-width: 1350px) {
    padding: 3rem;
    text-align: center;
    width: unset;
  }
`;

const CardContainer = styled(Container)`
  flex-grow: 1;
  width: 100%;
  padding: 2%;
  @media (max-width: 1350px) {
    padding: 0;
  }
`;

export { 
  CardsContainer,
  CardImageContainer,
  CardTextContainer,
  CardContainer
}
