import styled, { css, primaryTheme } from 'theme';

const textStyles = {
  h1: css`
    font-size: 72px;
    margin: 0;
    @media (max-width: 1350px) {
      font-size: 54px;
    }

  `,
  h2: css`
    font-size: 5.5rem;
  `,
  h3: css`
    font-size: 36px;
    margin: 32px 0 6px;
  `,
  h4: css`
    font-size: 1.4rem;
  `,
  h5: css`
    font-size: 1.2rem;
  `,
  body: css`
    font-size: 1rem;
  `,
  small: css`
    font-size: 1.8rem;
  `,
  tiny: css`
    font-size: 1.4rem;
  `,
  link: css`
    font-size: 1.25rem;
  `,
  heroDescription: css`
    font-size: 1.25rem;
    padding: 3rem 2rem 1rem;
    font-weight: 100;
    line-height: 1.3;
    margin: 0;
  `,
  cardDescription: css`
    padding: 2rem 0;
    font-weight: 100;
    line-height: 1.3;
    margin: 0;
  `,
  callout: css`
    font-size: 64px;
    padding: 10rem 21rem;
    font-weight: 700;
    line-height: 1.1;
    @media (max-width: 1500px) {
      font-size: 48px;
    }
    @media (max-width: 1350px) {
      padding: 0 5rem;
    }
  `,
  attention: css`
    font-size: 36px;
    padding: 0rem 25rem;
    line-height: 1.4;
    @media (max-width: 1350px) {
      padding: 0rem 5rem;
    }

  `,
  label: css`
    font-size: 1.25rem;
    letter-spacing: 0.075rem;
  `,
  default: css`
    font-size: 2.2rem;
  `,
};

const fontWeights = {
  light: css`
    font-weight: 100;
  `,
  book: css`
    font-weight: 300;
  `,
  medium: css`
    font-weight: 500;
  `,
  semiBold: css`
    font-weight: 600;
  `,
  bold: css`
    font-weight: 700;
  `,
  black: css`
    font-weight: 900;
  `,
  default: css`
    font-weight: 500;
  `
}

// output
export const StyledText = styled.p`
  ${({ textStyle = 'default' }) => textStyles[textStyle]};
  ${({ fontWeight = 'normal' }) => fontWeights[fontWeight]};
  font-size: ${({ fontSize }) => fontSize};
  display: ${({ display }) => display};
  text-align: ${({ textAlign }) => textAlign};
  font-style: ${({ fontStyle }) => fontStyle};
  text-transform: ${({ textTransform }) => textTransform};
  color: ${({color }) => color ? color : primaryTheme.colors.primary};
`;
