import styled, { css, primaryTheme } from 'theme';
import { Link } from 'react-router-dom';

const linkstyles = {
  menuList: css`
    &:hover{
      animation: fadeIn 200ms forwards;
      animation-timing-function: ease-in;
    }
    @keyframes fadeIn {
      from {background-color: transparent}
      to {background-color: ${primaryTheme.colors.secondaryHoverLight};}
    }
  `,
  list: css`
    font-size: 1rem;
  `,
  button: css`
    font-family: 'Akkurat', sans-serif;
    appearance: none;
    border: none;
    border-radius: 0.25rem;
    box-shadow: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1rem;
    @media (max-width: 700px) {
      font-size: 0.7rem;
    }

    font-weight: 700;
    padding: 0.5rem 1.4rem;
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    white-space: nowrap;

    &:disabled {
      background-color: ${primaryTheme.colors.disabled};
      opacity: 0.5;
      cursor: not-allowed;
    }
  `,
  default: css`
    font-size: 1rem;
  `,
};

export const StyledNavLink = styled(Link)`
  text-decoration: ${({ textDecoration }) => textDecoration};
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  &:focus{
    outline:0;
    border: 1px solid ${primaryTheme.colors.primary};
  }
  ${({ linkstyle = 'default' }) => linkstyles[linkstyle]};
`;

export const StyledLink = styled.a`
  text-decoration: ${({ textDecoration }) => textDecoration};
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  color: ${({ color }) => color};
  &:hover{
    border: 1px solid ${primaryTheme.colors.primary};
  }
  &:focus{
    outline:0;
    border: 1px solid ${primaryTheme.colors.primary};
  }
  ${({ linkstyle = 'default' }) => linkstyles[linkstyle]};
`;
